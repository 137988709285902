<template>
	<div
		class="product-list-item"
		:class="{ 'product-list-item--centered': isCentered }"
		:style="computedStyles"
		v-bind="CUSTOM_ATTRIBUTES"
	>
		<div class="product-list-item__content">
			<div
				v-qa="'product-list-section-item-image'"
				class="product-list-item__image-wrapper"
				:class="{ 'product-list-item__image-wrapper--contain': imageRatio === 'contain' }"
			>
				<ProductImage
					v-if="image"
					:src="image"
					:alt="title"
					class="product-list-item__image"
					:is-eager="isEager"
					:width="imageMaxWidth"
					:height="imageMaxWidth"
					:site-id="siteId"
					:object-fit="imageRatio"
					enable-srcset
					:is-lossless="true"
				/>

				<ProductImagePlaceholder
					v-else
					class="product-list-item__image"
				/>
				<ProductRibbon
					v-if="ribbon"
					:text="ribbon"
					:ribbon-style="ribbonStyle"
				/>
			</div>
			<h6
				v-qa="'product-list-section-item-title'"
				class="product-list-item__title"
			>
				{{ title }}
			</h6>
			<div
				v-if="price"
				v-qa="'product-list-section-item-price'"
				class="product-list-item__price-wrapper"
			>
				<template v-if="isInStock">
					<p>
						<span
							v-if="isPriceRangeShown && !price.sale_amount"
							class="product-list-item__price-range"
						>
							{{ translations.from }}
						</span>
						<span :class="{ 'product-list-item__price': price.sale_amount }">
							{{ formatPrice({
								amount: price.amount,
								currency: price.currency
							}) }}
						</span>
					</p>
					<p
						v-if="price.sale_amount"
						class="product-list-item__price-content"
					>
						<template v-if="isPriceRangeShown">
							{{ translations.from }}
						</template>
						{{ formatPrice({
							amount: price.sale_amount,
							currency: price.currency
						}) }}
					</p>
					<p
						v-if="productType === PRODUCT_TYPE_BOOKING"
						class="product-list-item__duration"
					>
						{{ duration }}
					</p>
				</template>
				<p
					v-else
					class="product-list-item__text"
				>
					{{ translations.soldOut }}
				</p>
			</div>
		</div>
		<div
			v-if="isButtonEnabled && isPurchasable"
			class="product-list-item__button-wrapper"
			:class="{
				'product-list-item__button-wrapper--hidden': !isInStock,
				'product-list-item__button-wrapper--with-hover': buttonDisplay === 'hover'
			}"
			@click.prevent.stop
		>
			<GridButton
				v-qa="`productlistsection-btn-addtobag`"
				:type="buttonType"
				:content="blockButtonText"
				class="product-list-item__button"
				:class="`product-list-item__button--${buttonType}`"
				tag-name="button"
				:border-width="buttonBorderWidth"
				:border-color="buttonBorderColor.normal"
				:border-color-hover="buttonBorderColor.hover"
				:background-color="buttonBackgroundColor.normal"
				:background-color-hover="buttonBackgroundColor.hover"
				@click="$emit('button-click')"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import { PRODUCT_TYPE_BOOKING } from '@zyro-inc/site-modules/constants/ecommerce';
import { formatPrice } from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';
import ProductImage from '@zyro-inc/site-modules/components/ecommerce/ProductImage.vue';
import ProductImagePlaceholder from '@zyro-inc/site-modules/components/ecommerce/-partials/ProductImagePlaceholder.vue';
import ProductRibbon from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductRibbon.vue';
import { computed } from 'vue';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import {
	DATA_ATTRIBUTE_ANIMATION_ROLE,
	DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT,
} from '@zyro-inc/site-modules/constants';
import { EcommerceVariantPrice } from '@zyro-inc/site-modules/types';

const CUSTOM_ATTRIBUTES = {
	[DATA_ATTRIBUTE_ANIMATION_ROLE]: DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT,
};

interface Props {
	image?: string | null;
	title: string;
	price?: EcommerceVariantPrice | null;
	isCentered?: boolean;
	isEager?: boolean;
	imageMaxWidth: number;
	duration: string;
	productType?: string;
	translations?: Record<string, string>;
	isStoreQuantityTracked: boolean;
	quantity: number;
	isButtonEnabled?: boolean;
	buttonDisplay?: string;
	buttonText?: string;
	buttonStyle?: Record<string, string>;
	buttonType?: string;
	buttonBorderWidth?: number;
	isPriceRangeShown?: boolean;
	ribbon?: string | null;
	ribbonStyle?: Record<string, string>;
	siteId: string;
	isPurchasable: boolean;
	imageRatio?: string;
}
const props = withDefaults(defineProps<Props>(), {
	image: '',
	isCentered: false,
	isEager: false,
	productType: '',
	translations: () => ({}),
	isButtonEnabled: false,
	buttonDisplay: '',
	buttonText: '',
	buttonStyle: () => ({}),
	buttonType: 'primary',
	buttonBorderWidth: 0,
	ribbon: '',
	ribbonStyle: () => ({}),
	siteId: '',
	imageRatio: 'cover',
	price: null,
});

defineEmits(['button-click']);

const isInStock = computed((): boolean => !props.isStoreQuantityTracked || props.quantity > 0);
const blockButtonText = computed((): string => props.buttonText || props.translations?.addToBag || 'Add to bag');
const buttonBorderColor = computed((): { normal: string, hover:string } => ({
	normal: props.buttonStyle[`grid-button-${props.buttonType}-border-color`],
	hover: props.buttonStyle[`grid-button-${props.buttonType}-border-color-hover`],
}));
const buttonBackgroundColor = computed((): { normal: string, hover:string } => ({
	normal: props.buttonStyle[`grid-button-${props.buttonType}-background-color`],
	hover: props.buttonStyle[`grid-button-${props.buttonType}-background-color-hover`],
}));
const computedStyles = computed((): Record<string, string> => ({
	...objectToCssVariables(props.buttonStyle),
}));
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@import "@zyro-inc/site-modules/components/blocks/ecommerce/-partials/shared";
@include font-style("h6", "h6", ".product-list-item");
@include font-style("body", "p", ".product-list-item");

.product-list-item {
	$this: &;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	cursor: pointer;

	&--centered {
		align-items: center;
		text-align: center;

		#{$this}__price-wrapper, #{$this}__button-wrapper {
			justify-content: center;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	&__image-wrapper {
		position: relative;
		display: flex;
		width: 100%;

		// variable defined on parent element
		height: var(--image-max-width);
		padding-bottom: 100%;
		overflow: hidden;
		transition: height 0.2s ease-in;

		&--contain {
			border: 1px solid $color-gray-border;

			#{$this} {
				&__image {
					// Fixed chrome bug with image border
					width: calc(100% + 2px);
					height: calc(100% + 2px);
					margin: -1px;

					// Center absolute position
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: transparent;
			transition: background-color 0.3s ease-in-out;
		}
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: v-bind(imageRatio);
		object-position: center;
	}

	&__title {
		word-break: break-word;
		margin: 20px 0 8px;
	}

	&__price-wrapper {
		display: flex;
	}

	&__price-content {
		display: flex;
	}

	&__price {
		margin-right: 8px;
		opacity: 0.4;

		&#{&} {
			text-decoration: line-through;
		}
	}

	&__price-range {
		margin-right: 4px;
	}

	&__text {
		&#{&} {
			text-transform: uppercase;
		}

		opacity: 0.6;
	}

	&__duration {
		&::before {
			margin: 0 8px;
			content: "|";
		}
	}

	&__button-wrapper {
		display: flex;
		width: 100%;
		margin-top: 8px;
		transition: opacity 0.3s ease-in;

		&--hidden {
			pointer-events: none;
			visibility: hidden;
		}

		&--with-hover {
			opacity: 0;
		}
	}

	&__button {
		position: relative;
		display: flex;
		align-items: center;
		height: var(--button-height);
		white-space: break-spaces;

		&--primary {
			margin: calc(-1 * var(--grid-button-primary-border-null, var(--grid-button-primary-border-width, 0px)));
			background-color: var(--grid-button-primary-background-color);

			&:focus,
			&:hover {
				margin: calc(-1 * var(--grid-button-primary-border-null-hover, var(--grid-button-primary-border-width-hover, 0px)));
				background-color: var(--grid-button-primary-background-color-hover, var(--grid-button-primary-background-color));
			}
		}

		&--secondary {
			margin: calc(-1 * var(--grid-button-secondary-border-null, var(--grid-button-secondary-border-width, 0px)));
			background-color: var(--grid-button-secondary-background-color);

			&:focus,
			&:hover {
				margin:
					calc(
						-1 * var(
							--grid-button-secondary-border-null-hover,
							var(--grid-button-secondary-border-width-hover, 0px)
						)
					);
				background-color:
					var(
						--grid-button-secondary-background-color-hover,
						var(--grid-button-secondary-background-color)
					);
			}
		}
	}
}

@include site-engine-mobile {
	.product-list-item {
		&__image-wrapper {
			height: 100%;
		}

		&__button-wrapper {
			pointer-events: none;

			&--with-hover {
				opacity: 1;
			}
		}
	}
}

@media screen and (min-width: $media-mobile) {
	.product-list-item {
		$this: &;

		&:hover,
		&:focus {
			#{$this}__image-wrapper {
				&::after {
					background-color: rgba($color-dark, 0.2);
				}
			}

			#{$this}__button-wrapper {
				opacity: 1;
			}
		}
	}
}

</style>
