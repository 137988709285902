<script setup lang="ts">
import ListSkeletonLoader from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ListSkeletonLoader.vue';
import ZyroPagination from '@zyro-inc/site-modules/components/ZyroPagination.vue';
import ProductListEmptyState from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListEmptyState.vue';
import ProductListItem from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListItem.vue';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH } from '@zyro-inc/site-modules/constants/defaultStyles';
import {
	getFormattedBookingDuration,
	isProductPriceRangeShown,
	getFullProductQuantity,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { getLowestPriceVariant } from '@zyro-inc/site-modules/utils/ecommerce/productUtils';
import {
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
} from '@zyro-inc/site-modules/constants';
import {
	ref,
	onMounted,
	computed,
	onBeforeUnmount,
	nextTick,
} from 'vue';
import {
	EcommerceProduct,
	EcommerceProductVariantQuantity,
	EcommerceVariantPrice,
} from '@zyro-inc/site-modules/types';
import { useRoute } from 'vue-router';

const GAP_SIZE = 24;

interface Props {
	blockId: string;
	products: Array<EcommerceProduct> | [];
	productPages?: Record<string, any>;
	blockStyle?: Record<string, string>;
	textColorVars?: Record<string, string>;
	isProductListShown: boolean;
	isLoading?: boolean;
	isEager?: boolean;
	columnCount?: number;
	productsPerPage?: number;
	translations: Record<string, string>;
	productIds?: Array<string> | [];
	productCategoryId?: string;
	isButtonEnabled?: boolean;
	buttonDisplay?: string;
	buttonText?: string;
	buttonStyle?: Record<string, string>;
	buttonType?: string;
	buttonBorderWidth?: number;
	ribbonStyle?: Record<string, string>;
	isProductListItemLinkDisabled?: boolean;
	siteId: string;
	variantsQuantity: Array<EcommerceProductVariantQuantity>;
	imageRatio?: string;
}

const props = withDefaults(defineProps<Props>(), {
	isProductListShown: true,
	columnCount: 3,
	productsPerPage: 9,
	buttonType: 'primary',
	productPages: () => ({}),
	blockStyle: () => ({}),
	textColorVars: () => ({}),
	productIds: () => ([]),
	productCategoryId: '',
	buttonDisplay: '',
	buttonText: '',
	buttonStyle: () => ({}),
	buttonBorderWidth: 0,
	ribbonStyle: () => ({}),
	imageRatio: 'cover',
});
const emit = defineEmits([
	'product-click',
	'button-click',
	'page-changed',
]);

const route = useRoute();

const currentPage = ref(1);
const isAnimationActive = ref(false);
const blockStorePageQuery = computed(() => `store-page-${props.blockId}`);
const pageCount = computed(() => Math.ceil(props.productIds.length / props.productsPerPage));
const customAttributes = computed(() => ({
	[DATA_ATTRIBUTE_ANIMATION_STATE]: isAnimationActive.value ? DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE : null,
}));
const modifiedCurrentPage = computed(() => {
	if (currentPage.value > pageCount.value) {
		return pageCount.value;
	}

	return currentPage.value < 1 ? 1 : currentPage.value;
});
const startIndex = computed(() => (modifiedCurrentPage.value - 1) * props.productsPerPage);
const endIndex = computed(() => startIndex.value + props.productsPerPage);
const targetIds = computed((): Array<string> => props.productIds.slice(startIndex.value, endIndex.value));
const currentPageProducts = computed(() => {
	const productValues = Object.values(props.products);
	const displayedProducts = productValues.filter((product: EcommerceProduct) => targetIds.value.includes(product.id as string));

	return displayedProducts.length ? displayedProducts : productValues.slice(startIndex.value, endIndex.value);
});
const emptyPageMessage = computed(() => props.translations.onlineStoreNoProducts || 'No publicly visible products');

const handleBrowserNavigationPageChange = () => {
	const params: URLSearchParams = new URLSearchParams(window.location.search);
	const pageParam: string = params.get(blockStorePageQuery.value) || '1';
	const pageFromParams: number = Number.parseInt(pageParam, 10);

	if (pageFromParams === currentPage.value) {
		return;
	}

	currentPage.value = pageFromParams;
};

onMounted(() => {
	const params = new URLSearchParams(window.location.search);
	const pageParam: string = params.get(blockStorePageQuery.value) || '1';
	const pageFromParams: number = Number.parseInt(pageParam, 10);

	if (pageFromParams !== currentPage.value) {
		currentPage.value = pageFromParams;

		emit('page-changed', targetIds.value);
	}

	window.addEventListener('popstate', () => {
		handleBrowserNavigationPageChange();
	});
});

onBeforeUnmount(() => {
	window.removeEventListener('popstate', handleBrowserNavigationPageChange);
});

const isCentered = computed(() => props.blockStyle?.textAlign === 'center');
const imageWidth = computed(() => {
	const totalGapsWidth = (props.columnCount - 1) * GAP_SIZE;
	const listWidth = DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH - totalGapsWidth;

	return Math.floor(listWidth / props.columnCount);
});
const computedStyles = computed(() => ({
	'--image-max-width': `${imageWidth.value}px`,
	'--gap-size': `${GAP_SIZE}px`,
	...objectToCssVariables(props.textColorVars),
}));

const handlePageChange = async (page: number) => {
	// reset animation first so it would be re-triggered on page change
	isAnimationActive.value = false;

	currentPage.value = page;

	emit('page-changed', targetIds.value);

	await nextTick();

	isAnimationActive.value = true;

	const block = document.getElementById(props.blockId);
	const blockRect = block?.getBoundingClientRect();
	const isInViewport = blockRect && blockRect.top >= 0 && blockRect.bottom <= window.innerHeight;

	if (!isInViewport) {
		block?.scrollIntoView({
			behavior: 'smooth',
		});
	}
};

const getItemProductPage = (productId: string | number) => Object.values(props.productPages).find((page) => page.productId === productId);

const getItemProductPageTo = (productId: string | number) => {
	if (props.isProductListItemLinkDisabled) {
		return route;
	}

	const productPage = getItemProductPage(productId);

	if (!productPage) {
		return {
			path: '/',
		};
	}

	return {
		path: `/${productPage.slug}`,
	};
};

const getSmallestProductPrice = (product: EcommerceProduct): EcommerceVariantPrice => (
	isProductPriceRangeShown(product) ? getLowestPriceVariant(product).prices[0] : product.variants[0].prices[0]
);

const getProductImage = (product: EcommerceProduct): string | null => {
	if (!isProductPriceRangeShown(product)) {
		return product.thumbnail;
	}

	return getLowestPriceVariant(product).image_url || product.thumbnail;
};
</script>

<template>
	<div
		:id="blockId"
		class="block-product-list-wrapper"
		:style="computedStyles"
	>
		<ListSkeletonLoader
			v-if="isLoading"
			:column-count="columnCount"
		/>
		<div
			v-else-if="isProductListShown"
			ref="productList"
			class="block-product-list"
		>
			<div class="block-product-list__content">
				<RouterLink
					v-for="(product, index) in currentPageProducts"
					:key="`product-${index}-${product.id}`"
					:to="getItemProductPageTo(product.id)"
					class="block-product-list__link"
				>
					<ProductListItem
						v-bind="customAttributes"
						:image="getProductImage(product)"
						:title="product.title"
						:ribbon="product.ribbon_text"
						:price="getSmallestProductPrice(product)"
						:is-centered="isCentered"
						:is-eager="isEager && index === 0"
						:duration="getFormattedBookingDuration(product, translations)"
						:image-max-width="imageWidth"
						:image-ratio="imageRatio"
						:is-store-quantity-tracked="product.variants[0].manage_inventory"
						:is-price-range-shown="isProductPriceRangeShown(product)"
						:quantity="getFullProductQuantity({
							product,
							variantsQuantity
						})"
						:product-type="product.type.value"
						:translations="translations"
						:is-button-enabled="isButtonEnabled"
						:button-display="buttonDisplay"
						:button-text="buttonText"
						:button-style="buttonStyle"
						:button-type="buttonType"
						:button-border-width="buttonBorderWidth"
						:ribbon-style="ribbonStyle"
						:is-purchasable="product.purchasable ?? true"
						:site-id="siteId"
						@click="$emit('product-click', product)"
						@button-click="$emit('button-click', product)"
					/>
				</RouterLink>
			</div>
			<ZyroPagination
				:current-page="currentPage"
				:page-count="pageCount"
				class="block-product-list__pagination"
				color="var(--body-color)"
				@change-page="handlePageChange($event)"
			/>
		</div>
		<ProductListEmptyState
			v-else
			:text-color-vars="textColorVars"
			:empty-page-message="emptyPageMessage"
		/>
	</div>
</template>

<style lang="scss" scoped>
@mixin product-list-mobile {
	.block-product-list-wrapper {
		padding: var(--m-block-padding);
	}

	.block-product-list {
		&__content {
			grid-template-columns: 1fr;
		}
	}
}

.block-product-list-wrapper {
	z-index: $z-index-site-engine-block-grid;
	display: flex;
	justify-content: center;
	padding: var(--block-padding);
}

.block-product-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	&__link {
		text-decoration: none;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(var(--image-max-width), 1fr));
		gap: var(--gap-size);
		width: 100%;
		max-width: var(--content-width);
	}

	&__pagination {
		margin-top: 16px;
	}
}

.zyro-mb-preview {
	@include product-list-mobile;
}

@media screen and (max-width: 700px) {
	@include product-list-mobile;
}

</style>
